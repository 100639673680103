@mixin trapezoid-button-hover-color($color) {
  &:hover {
    background-color: $color;

    &:after {
      background-color: $color;
    }
  }
}

@mixin trapezoid-button-color($color) {
  background-color: $color;

  &:after {
    background-color: $color;
  }
}

.trapezoid-button {
  background-color: $darker-gray;
  color: #fff;
  transform: skewX(-10rad);
  border-top-left-radius: 8px;
  position: relative;
  border: none;
  padding: 10px 20px;
  transition: background-color 100ms linear;

  &:after {
    content: '';
    position: absolute;
    transform: skewX(10rad);
    display: inline-block;
    right: -1px;
    z-index: -1;
    top: 0;
    height: 100%;
    width: 20px;
    background-color: $darker-gray;
    border-bottom-right-radius: 4px;
    transition: background-color 100ms linear;
  }

  &.is-wider {
    padding-left: 40px;
    padding-right: 40px;
  }

  &.is-absolute-bottom-right {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &.is-gold-hover {
    @include trapezoid-button-hover-color($gold);
  }

  &.is-red-hover {
    @include trapezoid-button-hover-color($darker-red);
  }

  &.is-red {
    @include trapezoid-button-color($darker-red);
  }

  &.is-gold-dark {
    @include trapezoid-button-color($gold-dark);
  }

  &.is-gold {
    @include trapezoid-button-color($gold);
  }

  &__text {
    color: white;
    transform: skewX(10rad);
    display: inline-block;
    font-weight: 600;
  }
}

.btn-red {
  border-color: $attention-red;
  background-color: $attention-red;
  color: #fff;

  &:hover {
    color: #fff;
    background-color: $gold;
    border-color: $gold;
  }

  &:focus {
    box-shadow: none;
  }
}

.btn-gold-dark {
  border-color: $gold-dark;
  background-color: $gold-dark;
  color: #fff;

  &:hover,
  &:active {
    color: #fff;
    background-color: $darker-red;
    border-color: $darker-red;
  }

  &:focus {
    box-shadow: none;
  }
}

.animated-button {
  position: relative;
  font-weight: 600;
  font-size: 0.9rem;
  width: 225px;
  height: 38px;
  transition: height 150ms linear;
  color: white;

  @include screen-sm-max {
    width: 100%;
    max-width: 400px;
  }

  &__default-text {
    display: block;
    text-transform: uppercase;
    position: absolute;
    top: 50%;
    white-space: nowrap;
    transform: translate(-50%, -50%);
    animation: buttonMoveBackward linear 150ms forwards;
  }

  &__hovered-text {
    display: none;
  }

  &:hover.is-full-red {
    background-color: $darker-red;
    border-color: $darker-red;
  }

  &:hover {
    height: 45px;

    .animated-button {
      &__default-text {
        display: none;
      }
    }

    .animated-button__hovered-text {
      display: block;
      position: absolute;
      top: 50%;
      white-space: nowrap;
      transform: translate(-50%, -50%);
      animation: buttonMoveForward linear 150ms forwards;
    }
  }

  &:hover.is-full-red {
    background-color: $darker-red;
    border-color: $darker-red;
  }

  &.h-48,
  &:hover {
    height: 48px;
  }
}

@-webkit-keyframes buttonMoveBackward {
  0% {
    left: 55%;
  }

  100% {
    left: 50%;
  }
}

@-webkit-keyframes buttonMoveForward {
  0% {
    left: 45%;
  }

  100% {
    left: 50%;
  }
}
