.header {
  display: flex;
  justify-content: space-between;
  position: relative;

  &__logo-white,
  &__logo-color {
    height: 40px;
    width: 130px;
  }

  &__logo-color {
    display: block;

    &.is-mobile-menu {
      @include screen-md-max {
        display: block !important;
        z-index: 101;
        position: sticky;
        left: 18px;
        top: -50px;
        padding-top: 10px;
        height: 50px;
      }
    }

    position: relative;

    &.is-mobile-menu:before {
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        height: 20px;
        background-color: white;
    }

  }

  &__logo-white {
    display: none;
  }

  &__dropdown-upper,
  &__dropdown-lower {
    padding: 17px 20px;
  }

  &__dropdown {
    padding: 0;
    border-radius: 5px;
    width: 280px;
    right: auto !important;
    left: 50% !important;
    transform: translate(-50%, 50px) !important;

    &:before {
      content: '';
      height: 16px;
      width: 16px;
      background-color: #fff;
      position: absolute;
      left: 50%;
      top: 0px;
      border-left: 1px solid rgba(0, 0, 0, 0.3);
      border-top: 1px solid rgba(0, 0, 0, 0.3);
      transform: translate(-50%, -50%) rotate(45deg);
      z-index: -1;
    }
  }

  &__hover-dropdown-list {
    list-style: none;
    padding-left: 0;
    box-sizing: border-box;
    cursor: pointer;
    margin-bottom: 0;

    &__item {
      position: relative;
      overflow: hidden;

      &:hover:after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: #f2f2f2;
      }

      &:hover {
        border-top: 1px solid rgba(#707070, 0.31);
        border-bottom: 1px solid rgba(#707070, 0.31);
        font-weight: $font-weight-bold;
        box-shadow: inset 0 0 1px 1px rgb(0 0 0 / 3%);
      }
    }

    & :first-child:hover {
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
      border-top: none;
    }

    & :last-child:hover {
      border-bottom-left-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      border-bottom: none;
    }
  }

  &__dropdown-lower {
    background-color: #f4f4f4;
  }

  &__dropdown-input {
    opacity: 0.7;
  }

  &__dropdown-link {
    color: $darker-gray;
    opacity: 0.7;
    text-decoration: none;
  }

  &__wrapper {
    position: fixed;
    background-color: white;
    box-shadow: 0px 3px 18px rgb(66 68 90 / 20%);
    width: 100%;
    z-index: 4;
    transition: background-color 0.3s ease-in;

    @include screen-md-max {
      z-index: 99;
    }

    &.is-transparent {
      box-shadow: none;
      border-bottom: 1px solid #fff;
      background-color: #00000045;
      -webkit-box-shadow: none;
      backdrop-filter: blur(10px);
    }
  }

  &__button {
    font-weight: 500;
    padding: 7px 14px 8px;

    @include screen-md-max {
      height: 47px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 500px;
    }
  }

  &__button-dropdown {
    font-weight: 500;
    border-color: $darker-gray;
    color: $darker-gray;
  }

  &__desktop {
    display: contents;

    @include screen-md-max {
      display: none;
    }
  }

  &__links-wrapper {
    @include screen-md-max {
      position: fixed;
      height: 100vh;
      width: 100vw;
      background: white;
      left: 0;
      top: 0;
      padding: 50px 13px;
      margin: 0;
      flex-direction: column;
      transition: transform ease-in 0.3s;
      transform: translateX(100%);
      overflow: auto;
      z-index: 100;

      .mobile-menu {
        opacity: 0;
        transition: opacity 0.3s 0.2s;
      }

      &.active {
        transform: translateX(0%);

        .mobile-menu {
          opacity: 1;
        }

        .is-mobile-menu {
          display: block;
        }
      }
    }
  }

  &__link {
    color: $darker-gray;
    list-style-type: none;
    font-weight: 500;

    &:hover {
      color: $primary;
      cursor: pointer;
    }
  }

  &__hamburger {
    align-self: center;
    width: 27px;
    height: 16px;
    border: 0;
    border-top: 2px solid $dark-blue;
    background-color: transparent;
    transition: transform 0.3s ease, border-top-color 0.3s ease;
    position: relative;
    display: none;
    margin-left: 30px;
    cursor: pointer;
    z-index: 200;

    @include screen-md-max {
      display: block;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      border-top: 2px solid $dark-blue;
      transform: translateY(12px);
      transition: 0.3s all ease;
    }

    &:after {
      transform: translateY(5px);
      width: 120%;
    }

    &:before {
      width: 80%;
    }

    &.active {
      transform: rotate(45deg);
      border: none;
      margin: 0;
      width: 31px;
      height: 22px;
      right: 0;
      top: 0;

      &:after,
      &:before {
        border-top: 2px solid $dark-blue;
        width: 100%;
      }

      &:after {
        transform: rotate(-90deg) translateX(-9px);
      }

      &:before {
        transform: translateY(9px);
      }
    }
  }

  &__wrapper.is-transparent {
    .header__link {
      color: #fff;

      @include screen-md-max {
        color: $dark-blue;
      }
    }

    .header__button-dropdown {
      border-color: #fff;
      color: #fff;

      @include screen-md-max {
        border-color: $dark-blue;
        color: $dark-blue;
      }
    }

    .header__hamburger:not(.active) {
      border-top-color: #fff;

      &:before,
      &:after {
        border-top-color: #fff;
      }
    }

    &.is-mainpage .header__logo-color {
      display: none;
    }

    &.is-mainpage .header__logo-white {
      display: block;
    }
  }

  &__wrapper.is-mainpage {
    .header__logo-color {
      display: block;
    }

    .header__logo-white {
      display: none;
    }
  }
}

.hover-dropdown {
  position: relative;
  display: inline-block;

  &:hover {
    .hover-dropdown__content-wrapper {
      display: block;
    }
  }

  &__content-wrapper {
    display: none;
    position: absolute;
  }

  &__content {
    width: 200px;
    margin-top: 24px;
    min-width: 160px;
    z-index: 1;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    background-color: white;

    a {
      text-decoration: none;
      color: $darker-gray;
      position: relative;
      z-index: 1;
    }

    &:before {
      content: '';
      height: 16px;
      width: 16px;
      background-color: #fff;
      position: absolute;
      left: 30px;
      top: 24px;
      border-left: 1px solid rgba(0, 0, 0, 0.3);
      border-top: 1px solid rgba(0, 0, 0, 0.3);
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }
}

.modal-open {
  .header__wrapper {
    padding-right: 15px;
  }
}
