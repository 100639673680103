.footer {
  background: $dark-blue;
  position: relative;
  overflow: hidden;
  padding-top: 20px;
  display: block;

  &__logo {
    height: 40px;
    width: 130px;
  }

  &__arrow_top {
    height: 30px;
    width: 30px;
  }

  @include screen-md-max {
    display: none;
  }

  &.is-mobile {
    display: none;

    @include screen-md-max {
      display: block;
    }
  }

  &__img-line {
    width: 100%;
    height: auto;
    display: block;
  }

  &__shape {
    position: absolute;
    left: -25px;
    top: -279px;
    pointer-events: none;
  }

  &__mobile-button {
    background-color: $dark-blue;
    color: $gold;
    font-weight: 600;

    &:focus,
    &:not(.collapsed) {
      background-color: $dark-blue;
      box-shadow: none;
    }

    &:after {
      background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23a7834b%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e');
    }
  }

  &__social__instagram,
  &__social__youtube,
  &__social__fb {
    height: 30px;
    width: 30px;
  }
  &__social-section {
    padding: 1rem 1.25rem;
  }

  &__accordion-wrapper {
    border-bottom: 1px solid $darker-gray;
  }

  &__newsletter {
    width: 180px;

    @include screen-md-max {
      width: 100%;
    }

    &__input {
      border-radius: 0.25rem;
      padding: 5px 10px;
      min-width: 100px;
      max-width: 500px;
      outline: none;
      border-color: inherit;
      border-width: 0;
      z-index: 1;

      &:focus {
        outline: none;
      }

      @include screen-md-max {
        height: 45px;
        text-align: center;
        width: 100%;
        margin: 0 auto;
      }
    }

    &__button {
      color: $white;
      border-radius: 0.25rem;
      background-color: $primary;
      padding: 5px 10px;
      min-width: 100px;
      max-width: 500px;

      @include screen-md-max {
        font-weight: bold;
        width: 100%;
        margin: 10px auto 0 auto;
        height: 45px;
        text-align: center;
      }
    }
  }

  &__reward {
    display: block;
    font-size: $font-sm;
    color: $white;
    text-align: center;

    &:not(.success){
      margin-top: 10px;
    }

    @include screen-lg-min {
      max-width: 70px;
      margin-top: 0;
    }

    @include screen-md-max {
      margin-top: 0 !important;
    }
  }

  &__links {
    display: flex;
    justify-content: space-evenly;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px 0;

    &__wrapper {
      position: relative;
      z-index: 1;
      background: white;

      @include screen-md-max {
        display: none;
      }
    }
  }

  &.has-booking-button {
    padding-bottom: 155px;

    @include screen-md-max {
      padding-bottom: 75px;
    }
  }
}
