.styled-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    position: relative;
    cursor: pointer;
    padding: 6px 0;
    display: flex;
    align-items: center;
  }

  // Box.
  & + label:before {
    content: '';
    margin-right: 10px;
    margin-top: -1px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    background: #f8f8f8;
    border: 1px solid #9099a1;
    border-radius: 2px;
  }

  &.is-radio + label:before {
    border-radius: 50%;
  }

  // Box checked
  &:checked + label:before {
    background: $gold;
    border-color: $gold;
  }

  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:before {
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 2px;
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="14" height="14" viewBox="0 0 24 24"%3E%3Cpath fill="white" d="M9 16.17L5.53 12.7a.996.996 0 1 0-1.41 1.41l4.18 4.18c.39.39 1.02.39 1.41 0L20.29 7.71a.996.996 0 1 0-1.41-1.41L9 16.17z"%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat;
    background-position: 2px;
  }
}
