.regulation {
  &__list {
    margin-bottom: 30px;

    .accordion-button {
      padding-block: 3px;
      padding-left: 3px;
      font-size: 12px;

      &:after {
        width: 1rem;
        height: 1rem;
        background-size: 1rem;
      }

      &:not(.collapsed) {
        color: $darker-gray;
        box-shadow: none;
        background-color: initial;
      }

      &:focus {
        box-shadow: none;
        border-color: initial;
      }
    }

    .accordion-body {
      padding-top: .5rem;

      ul {
        padding-left: 0;
        margin-bottom: 0;
      }
    }
  }
}

