.career {
  &__section {
    margin-top: 51px;
    margin-bottom: 55px;
    padding: 0 0 43px;
    text-align: center;

    @include screen-md-max {
      margin-block: 0;
      padding-bottom: 20px;
    }

    &__header {
      font-size: 20px;
      color: $primary;
      background-color: #fff;
      padding: 24px 10px 20px;
      border-bottom: 1px solid rgba(112, 112, 112, 0.27);
    }
  }

  &__image {
    width: 100%;
    max-height: 500px;
    object-fit: cover;
    object-position: 0 -150px;

    @include screen-md-max {
      max-height: 100%;
      object-position: top;
    }
  }

  &__process-point {
    font-size: 61px;
    margin-right: 15px;
    font-weight: bold;
    color: $primary;
    opacity: 0.6;
  }

  &__process {
    &__list {
      li {
        @include screen-lg-max {
          margin-bottom: 10px;
        }

        @include screen-sm-max {
          margin-bottom: 40px;
        }
      }
    }
  }
}
