.prices {
  &__section-wrapper {
    &.is-azure-on-mobile {
      @include screen-md-max {
        background-color: $light-azure;
      }
    }
  }

  &__section {
    margin-top: 51px;
    margin-bottom: 55px;
    padding: 0 0 43px;

    @include screen-md-max {
      margin-top: 0;
      margin-bottom: 25px;
      padding-bottom: 0;
    }

    &.is-azure-on-mobile {
      @include screen-md-max {
        background-color: $light-azure;
      }
    }
  }

  &__section-header {
    background-color: #fcfcfc;
    padding: 24px 10px 20px;
    border-bottom: 1px solid rgba(112, 112, 112, 0.27);

    @include screen-md-max {
      background-color: #fff;
      border-bottom: none;
      padding-bottom: 0;
    }

    &.is-transparent {
      background-color: transparent;
      border-bottom: none;

      @include screen-md-max {
        background-color: transparent;
      }
    }

    &__resort-selection {
        padding: 7px 26px;
        border: 1px solid $dark-blue;
        border-radius: 4px;
        background-color: #fff;
        color: $dark-blue;
        position: relative;
        width: 30%;

        &.is-active,
        &:hover,
        &:active,
        &:focus {
          background-color: $dark-blue;
          color: #fff;
        }
    }
  }

  &__section-content {
    padding: 40px 45px 0;

    @include screen-md-max {
      padding: 30px 15px 0;
    }

    &.is-transparent {
      padding-top: 0;

      @include screen-md-max {
        padding-top: 30px;
      }
    }
  }

  &__calculator-summary {
    background-color: #f8f8f9;
    margin-left: 80px;

    @include screen-lg-max {
      margin-left: 20px;
    }

    @include screen-md-max {
      margin-left: 0;
    }
  }

  &__calculator-rrso {
    max-width: 684px;
    margin: 35px auto 0;

    @include screen-md-max {
      margin: 20px auto 0;
    }
  }

  &__calculator-summary-row {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &__calculator-cost {
    background-color: $darker-red;
    padding: 8px 33px;
  }

  &__slider-btn {
    cursor: pointer;
    color: #fff;
    background-color: $darker-red;
    font-size: 15px;
    font-weight: 700;
    border-radius: 50%;
    width: 24px;
    height: 23px;
    text-align: center;
    display: inline-block;
  }

  &__range-sublabel {
    @include screen-md-max {
      position: absolute;
      left: 0;
      bottom: -23px;
      font-size: 11px;
    }

    &:last-of-type {
      width: 41px;
      text-align: left;

      @include screen-md-max {
        width: initial;
        left: initial;
        right: 0;
      }
    }
  }

  &__range-label {
    margin-right: 37px;

    @include screen-md-max {
      width: 100%;
      margin-right: 0;
      margin-bottom: 10px;
      font-size: 13px;
    }
  }

  &__range-management {
    margin-bottom: 50px;

    &:last-child {
      margin-bottom: 10px;

      @include screen-md-max {
        margin-bottom: 50px;
      }
    }
  }

  &__range-helper {
    text-align: center;
    top: -17px;
    white-space: nowrap;
    right: 0;
    margin: auto;

    @include screen-md-max {
      top: initial;
      bottom: -30px;
    }
  }

  &__range-wrapper {
    margin-top: -6px;
    width: 283px;

    @include screen-md-max {
      width: initial;
      flex: 1 1 auto;
    }
  }

  /********** Range Input Styles **********/
  $track-height: 5px;
  $thumb-height: 21px;

  &__range {
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 100%;
    height: $track-height;
    z-index: 1;
    position: relative;

    &:focus {
      outline: none;
    }

    &::-webkit-slider-runnable-track {
      background-color: #bcc2d3;
      border-radius: 0.5rem;
      height: $track-height;
      margin-top: 7px;
    }

    &::-moz-range-track {
      background-color: #bcc2d3;
      border-radius: 0.5rem;
      height: $track-height;
    }

    &::-webkit-slider-thumb {
      appearance: none;
      margin-top: -10px;
      background-color: $primary;
      border: none;
      width: 23px;
      height: 23px;
      border-radius: 50%;
    }

    &::-moz-range-thumb {
      border: none;
      background-color: $primary;
      width: 23px;
      height: 23px;
      border-radius: 50%;
    }
  }

  &__year-btn {
    padding: 7px 26px;
    border: 1px solid $dark-blue;
    border-radius: 4px;
    background-color: #fff;
    color: $dark-blue;
    position: relative;
    width: 100px;

    &.active,
    &:hover,
    &:active,
    &:focus {
      background-color: $dark-blue;
      color: #fff;
    }
  }

  &__year-btn-patch {
    color: #fff;
    position: absolute;
    right: -14px;
    top: -16px;
    width: 32px;
    height: 32px;
    background-color: $darker-red;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 9px;

    @include screen-md-max {
      position: initial;
      margin-left: 10px;
    }

    &.in-accordion-body {
      @include screen-md-max {
        position: absolute;
        margin: auto;
        top: -35px;
        bottom: 0;
        right: 16px;
      }
    }

    &.is-subscription {
      @include screen-md-max {
        background-color: $secondary;
        position: absolute;
        margin: auto;
        top: 35px;
        bottom: 0;
        right: 16px;
      }
    }

    &__subscription-hint {
    }
  }

  &__table-head,
  &__table-body {
    vertical-align: middle !important;
  }

  &__table-head {
    th {
      padding: 19px 0;
    }
  }

  &__table-body {
    td {
      padding: 16px 0;
    }
  }

  &__table-row {
    &:nth-child(even) {
      background-color: rgba(10, 23, 62, 0.03);
    }
  }

  &__table-btn {
    border-radius: 4px;
    padding: 6px 14px;
  }

  &__accordion-btn {
    padding-top: 7px;
    padding-bottom: 7px;
    border-radius: 4px;
    font-weight: 700;
    border: 1px solid $dark-blue;
    color: #fff !important;
    background-color: $dark-blue !important;
    outline: none;

    &:active,
    &:focus {
      outline: none;
      box-shadow: none;
      border-color: $dark-blue;
    }

    &:after {
      position: absolute;
      right: 1.25rem;
      background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e') !important;
    }

    &.collapsed {
      color: $dark-blue !important;
      background-color: #fff !important;

      &:after {
        background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%230a173e%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e') !important;
      }
    }
  }

  &__accordion-body {
    margin-top: 10px;
    border: 1px solid rgba(112, 112, 112, 0.27);
    padding: 0;
  }

  &__accordion-row {
    position: relative;
    color: $dark-blue;
    text-align: center;
    padding: 20px 10px;

    &:nth-child(odd) {
      color: $gold;
      background-color: rgba(10, 23, 62, 0.03);
    }
  }

  &__accordion-item {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__icon-text {
    line-height: 15px;
    position: absolute;
    left: 59px;
    width: 105px;

    @include screen-md-max {
      position: static;
      text-align: center;
      font-size: 13px;
      font-weight: 700;
      text-transform: uppercase;
      margin-top: 14px;
      width: initial;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    margin-left: -90px;
    position: relative;

    @include screen-md-max {
      flex-direction: column;
      margin-left: 0;
    }

    img {
      width: 35px;
      height: auto;

      @include screen-md-max {
        height: 35px;
        width: auto;
      }
    }
  }

  &__icons {
    justify-items: center;
    gap: 50px 10px;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    @include screen-md-max {
      row-gap: 30px;
      align-items: flex-start;
      grid-template-columns: 1fr 1fr;
    }
  }

  &__imp-label {
    background-color: #e9f4eb;
    border: 1px solid rgba(44, 143, 65, 0.4);
    border-radius: 5px;
    color: $green-pearl;
    padding: 0px 20px 0 3px;
    line-height: 0px;
  }

  &__imp-content {
    background-color: $light-azure;
    padding-top: 21px;
    padding-bottom: 67px;
    border: 1px solid $darker-gray;
    border-top: none;
    flex-grow: 1;
  }

  &__imp-section {
    gap: 15px;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    @include screen-lg-max {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include screen-md-max {
      grid-template-columns: 1fr 1fr;
    }

    @include screen-sm-max {
      grid-template-columns: 1fr;
    }
  }

  &__imp-card {
    box-shadow: none;
    margin-top: 0;
    margin-right: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__imp-btn {
    min-width: 114px;
    font-size: 14px;
    font-weight: 700;
    cursor: default !important;
  }

  &__extra-row {
    padding: 10px 18px;
    min-height: 30px;
    align-items: center;
    margin-bottom: 0;
    text-align: start;

    &:nth-child(even) {
      background-color: rgba(10, 23, 62, 0.03);
    }
  }

  &__reservation-box-el {
    border-right: 1px solid #dadada;

    &:last-child {
      width: 25%;
      border-right: none;

      @include screen-md-max {
        width: 100%;
      }
    }
  }

  &__improvements-prices-link {
    margin-top: -85px;
    display: block;
    color: $primary;
    font-weight: 500;
    text-align: center;

    @include screen-md-max {
      margin-top: 0;
      margin-bottom: 20px;
    }
  }

  &__additional-cost-info {
    overflow: hidden;
    margin-top: -80px;
    padding-top: 0;

    @include screen-md-max {
      margin-top: 0;
    }
  }
}
