.packages {
  &__section-wrapper {
    @include screen-md-max {
      padding: 0 15px;
    }

    &.is-azure-on-mobile {
      @include screen-md-max {
        background-color: $light-azure;
      }
    }
  }

  &__section {
    margin-top: 51px;
    margin-bottom: 55px;
    padding: 0 0 43px;

    @include screen-md-max {
      margin-top: 0;
      margin-bottom: 25px;
    }

    &.is-azure-on-mobile {
      @include screen-md-max {
        background-color: $light-azure;
      }
    }
  }

  &__section-header {
    background-color: #fcfcfc;
    padding: 24px 10px 20px;
    border-bottom: 1px solid rgba(112, 112, 112, 0.27);

    @include screen-md-max {
      background-color: #fff;
      border-bottom: none;
      padding-bottom: 0;
    }

    &.is-azure-on-mobile {
      @include screen-md-max {
        background-color: $light-azure;
      }
    }
  }

  &__section-content {
    padding: 0 100px 0;

    @include screen-md-max {
      padding: 30px 15px 0;
    }

    &.is-more-vertical-space {
      padding-top: 60px;
      padding-bottom: 50px;

      @include screen-md-max {
        padding: 30px 15px 0;
      }
    }

    &.is-less-horizontal-space {
      padding-left: 45px;
      padding-right: 45px;

      @include screen-md-max {
        padding: 30px 15px 0;
      }
    }

    &__subscription-header {
      width: 25%;
      font-weight: 500;
      margin-left: auto;

      @include screen-md-max {
        display: none;
      }
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    margin-bottom: 24px;

    @include screen-md-max {
      margin-bottom: 26px;
    }

    &__header-image {
      height: 350px;
      object-fit: cover;
      margin-inline: -100px;
      width: calc(100% + 200px);

      @include screen-md-min {
        object-position: 0 -215px;
      }
    }
  }

  &__card-img {
    margin-block: 6px;
    object-fit: cover;
    height: 210px;

    @include screen-md-max {
      width: 100%;
      min-width: initial;
    }
  }

  &__card-header {
    font-size: 14px;
    font-weight: 700;
    border-bottom: 1px solid #e2e2e2;
    padding-block: 10px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    display: flex;
    color: white;
    align-items: center;
    justify-content: center;

    &.is-gold {
      background-color: $gold;
    }

    &.is-silver {
      background-color: #a3a3a3;
    }

    &.is-brown {
      background-color: #562c0c;
    }
  }

  &__card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f8f9fa;
    padding-block: 30px;
    padding-inline: 20px;
    position: relative;

    @include screen-xs-max {
      padding-bottom: 17px;
    }

    &__alternative-separator {
      position: absolute;
      left: 50%;
      top: -23px;
      padding-inline: 12px;
      border-radius: 4px;
      padding-block: 7px;
      font-weight: bold;
      color: white;
      transform: translateX(-50%);

      &.is-gold {
        background-color: $primary;
      }

      &.is-silver {
        background-color: #a3a3a3;
      }

      &.is-brown {
        background-color: #562c0c;
      }
    }
  }

  &__card-list {
    padding-left: 17px;
  }

  &__small-cards-wrapper {
    gap: 0 30px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    @include screen-md-max {
      grid-template-columns: 1fr 1fr;
    }

    @include screen-sm-max {
      grid-template-columns: 1fr;
    }
  }

  &__cieplice-map {
    grid-column: span 2;
    height: 100%;
    width: auto;
    margin-top: 37px;
    margin-left: auto;

    @include screen-md-max {
      grid-column: 1;
      width: 100%;
      height: auto;
    }

    @include screen-sm-max {
      display: none;
    }
  }

  &__small-card-img {
    min-height: 150px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  &__dates-el {
    padding: 8px 20px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:nth-child(1) {
      width: 50%;
      justify-content: flex-start;

      @include screen-md-max {
        justify-content: center;
      }
    }

    &:nth-child(2) {
      width: 25%;
    }

    &:nth-child(3) {
      width: 25%;
    }

    &:first-child {
      text-align: left;

      @include screen-md-max {
        width: 100%;
        text-align: center;
      }
    }

    &:nth-child(2),
    &:nth-child(3) {
      @include screen-md-max {
        width: 100%;
      }
    }

    &:last-child {
      @include screen-md-max {
        margin-bottom: 10px;
      }
    }

    &.is-grey-bg {
      background-color: #f5f5f5;

      @include screen-md-max {
        background-color: #fff;
      }
    }

    &.is-gold-dark-bg {
      background-color: #8e7245;

      @include screen-md-max {
        background-color: #947749;
      }
    }

    &.is-secondary-light-bg {
      opacity: 0.95;
      background-color: $secondary;
    }

    &.is-gold-bg {
      background-color: #947749;
    }
  }

  &__subscription-hint {
    margin-left: 35px;
    position: relative;
    font-weight: 500;
    display: none;

    @include screen-md-max {
      display: block;
    }

    &:before {
      content: '';
      position: absolute;
      left: -20px;
      top: 0;
      width: 15px;
      height: 15px;
      background-color: $secondary;
    }

    &.is-rounded {
      &:before {
        border-radius: 50%;
      }
    }
  }

  &__term-button {
    @include screen-md-max {
      margin-inline: 0 !important;
      width: 100%;
    }
  }

  &__special-dates,
  &__service-price-table {
    width: 75%;
    margin: 0 auto;

    @include screen-md-max {
      width: 100%;
    }
  }

  &__service-price-table {
    td {
      border: none;
      color: $darker-gray !important;
      font-size: 18px;
      vertical-align: middle;
      text-align: left;
      padding: 8px 20px;
    }

    tr:nth-child(even) {
      background-color: white;
    }

    tr:nth-child(odd) {
      background-color: #f5f5f5;
    }
  }

  &__faq {
    .accordion-button {
      &:not(.collapsed) {
        background-color: initial;
      }

      &:focus {
        box-shadow: none;
        border: none;
      }
    }
  }

  &__discount-animated {
    width: 24%;
    background-color: $alice-blue;
    height: 50px;
    border-radius: 0.25rem;
    cursor: default;

    &:hover {
      height: 50px;
      background-color: $danger;

      .animated-button__hovered-text > * {
        color: white !important;
      }
    }

    .animated-button__default-text {
      text-transform: lowercase;
      font-weight: normal;
    }
  }
}
