.attractions {
  &__section {
    margin-top: 51px;
    margin-bottom: 55px;
    padding: 0 0 43px;

    @include screen-md-max {
      margin-top: 0;
      margin-bottom: 25px;
    }
  }

  &__section-header {
    background-color: #fcfcfc;
    padding: 24px 10px 20px;
  }

  &__section-content {
    padding: 40px 45px 0;

    @include screen-md-max {
      padding: 30px 15px 0;
    }

    .gallery {
      padding: 0;
    }

    &>.gallery {
      margin-top: -48px;
      margin-bottom: 48px;
      margin-inline: -45px;

      @include screen-sm-max {
        margin-inline: -15px;
        margin-bottom: -12px;
        margin-top: -36px
      }
    }
  }

  &__wrapper {
    gap: 25px;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    @include screen-lg-max {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include screen-md-max {
      grid-template-columns: 1fr 1fr;
    }

    @include screen-sm-max {
      grid-template-columns: 1fr;
    }
  }

  &__card {
    box-shadow: none;
    margin-top: 0;
    margin-right: 0;
    width: 100%;
  }

  &__card-content {
    background-color: $light-azure;
    padding-top: 21px;
    padding-bottom: 21px;
    border: 1px solid $darker-gray;
    border-top: none;
  }
}