.weather {
  position: fixed;
  top: 50%;
  left: -110vw;
  transform: translateY(-50%);
  height: 400px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  padding-inline: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  transition: left 300ms ease-in-out;
  background-color: white;
  z-index: 3;

  &.is-visible {
    left: 0;
  }

  @include screen-md-max {
    height: auto;
    padding: 8px;
    transition: right 150ms ease-in-out;
    border-radius: 4px 0 0 4px !important;
    left: auto;
    right: -90px;
    z-index: 3;
    top: calc(30% + 160px);

    &__background {
      object-position: 0 !important;
      border-radius: 4px 0 0 4px !important;
    }

    &__arrows {
      display: none;
    }

    &__sun {
      margin-top: 10px !important;
      margin-bottom: 10px !important;
      height: 60px !important;
    }

    &.is-visible {
      right: 0;
      left: auto;
      z-index: 2;
    }
  }

  &__text {
    z-index: 1;
    align-self: start;
    font-size: $font-xl;
  }

  &__sun {
    margin-top: 45px;
    z-index: 1;
    height: 60px;
    animation: rotating 10s linear infinite;
  }

  &__background {
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
    object-position: -225px;
    height: 100%;
    width: 100%;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    opacity: 0.1;
    z-index: 0;
  }

  &__arrows {
    font-size: 45px;
    color: $secondary;
    margin-top: auto;
    align-self: start;
    line-height: 1;
    position: relative;

    &__first {
      position: absolute;
      left: 20px;
      bottom: 20px;
    }

    &__second {
      position: absolute;
      left: 32px;
      bottom: 20px;
    }
  }

  &__content {
    background-color: white;
    z-index: 3;
    display: flex;
    position: fixed;
    left: -110vw;
    top: 50%;
    transform: translateY(-50%);
    padding: 50px;
    justify-content: center;
    align-items: center;
    transition: left 300ms ease-in-out;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    @include screen-md-max {
      display: none;
    }

    &.is-visible {
      left: 0;
    }

    &__background {
      position: absolute;
      left: 0;
      object-fit: cover;
      height: 100%;
      width: 100%;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      opacity: 0.1;
      z-index: -1;
    }

    &__resort {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      cursor: pointer;
      position: relative;

      &__city {
        line-height: 1;
        font-weight: 500;
        font-size: $font-xl;
        margin-bottom: 0;
      }

      &__street {
        font-weight: 500;
        font-size: $font-sm;
      }

      &__play {
        color: $primary;
        margin-right: 15px;
        font-size: $font-xxl;
      }

      &.is-active {
        .weather__content__resort {
          &__city,
          &__play {
            color: $secondary;
          }

          &__street {
            color: $primary;
          }
        }
      }
    }

    &__close {
      color: $primary;
      font-size: 30px;
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
      z-index: 1;
    }

    &__play-icon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 65px;
      color: white;
      pointer-events: none;

      @include screen-md-max {
        top: 40%;
        font-size: 45px;
      }
    }

    &__title,
    &__resorts,
    &__video {
      margin-inline: 40px;
    }

    &__title {
      .weather__sun {
        margin-top: 0;
      }

      display: flex;
      flex-direction: column;
    }

    &__resorts {
      margin-bottom: 0;
    }

    &__video {
      border-radius: 4px;
      height: 290px;
      width: 500px;
      max-height: 290px;
      max-width: 500px;
      z-index: 1;
      object-fit: cover;
      position: relative;

      &__icon {
        position: absolute;
        top: 15px;
        z-index: 1;
        left: 15px;

        @include screen-sm-min {
          left: 50px;
        }
      }
    }
  }

  &__content__mobile {
    display: none;
    position: fixed;
    transform: translateY(100%);
    top: 67px;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    transition: transform 250ms ease-in-out;
    z-index: 3;
    overflow-y: auto;

    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      background: rgba(87, 96, 103, 0.1);
      top: -67px;
      opacity: 0;
      transition: opacity 500ms ease-in-out;
      z-index: -1;
    }

    &.is-visible {
      transform: translateY(0);

      &:before {
        opacity: 1;
      }
    }

    @include screen-md-max {
      display: block;
    }

    &__video {
      min-height: 200px;
      width: 100%;
      min-width: 285px;
      margin-top: 10px;
      z-index: 1;
      position: relative;
    }

    &__row {
      overflow: auto;
      padding-bottom: 80px;
      margin-top: 60px;
      margin-inline: 0;
    }
  }

  &__placeholder {
    &__image {
      border-radius: 4px;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      @include screen-md-max {
        background-color: #c69e63;
        object-position: 14px -35px;
      }
    }

    &__text {
      color: white;
      text-align: start;
      font-size: 20px;
      line-height: 25px;
      margin-top: 10px;
    }

    &__content {
      position: relative;
      padding: 35px;
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    &__streaming-badge {
      font-weight: bold;
      font-size: 9px;
      color: white;
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      &__live {
        height: 17px;
        padding: 4px 6px;
        position: relative;
        background-color: #da001a;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;

        &:before {
          content: '';
          width: 9px;
          height: 9px;
          background-color: white;
          border-radius: 50%;
          display: inline-block;
          margin-right: 4px;
        }
      }

      &__streaming {
        height: 17px;
        padding: 3px 6px;
        background-color: #373737;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }

  &__counter {
    .tick-group {
      display: flex;
      align-items: center;
      font-size: 25px;
      margin-right: 5px;
    }

    .tick-credits {
      display: none;
    }
  }
}

@-webkit-keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
