//
// Nunito fonts
//

@font-face {
  font-family: 'museo_sans';
  src: url('../fonts/museosanscyrl-300-webfont.eot');
  src: url('../fonts/museosanscyrl-300-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/museosanscyrl-300-webfont.woff2') format('woff2'),
    url('../fonts/museosanscyrl-300-webfont.woff') format('woff'),
    url('../fonts/museosanscyrl-300-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  //font-display: swap;
}

@font-face {
  font-family: 'museo_sans';
  src: url('../fonts/museosanscyrl-100-webfont.eot');
  src: url('../fonts/museosanscyrl-100-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/museosanscyrl-100-webfont.woff2') format('woff2'),
    url('../fonts/museosanscyrl-100-webfont.woff') format('woff'),
    url('../fonts/museosanscyrl-100-webfont.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  //font-display: swap;
}

@font-face {
  font-family: 'museo_sans';
  src: url('../fonts/museosanscyrl-700-webfont.eot');
  src: url('../fonts/museosanscyrl-700-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/museosanscyrl-700-webfont.woff2') format('woff2'),
    url('../fonts/museosanscyrl-700-webfont.woff') format('woff'),
    url('../fonts/museosanscyrl-700-webfont.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  //font-display: swap;
}

@font-face {
  font-family: 'museo_sans';
  src: url('../fonts/museosanscyrl-500-webfont.eot');
  src: url('../fonts/museosanscyrl-500-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/museosanscyrl-500-webfont.woff2') format('woff2'),
    url('../fonts/museosanscyrl-500-webfont.woff') format('woff'),
    url('../fonts/museosanscyrl-500-webfont.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  //font-display: swap;
}
