@mixin row-colors($first, $second) {
  .vip__prices__row:nth-child(#{$first}) {
    .vip__prices__row__title {
      background-color: #f1f1f1;
    }

    .vip__prices__row__discount {
      background-color: #8e7245;
    }

    .vip__prices__row__value {
      background-color: #f1f1f1;
    }
  }

  .vip__prices__row:nth-child(#{$second}) {
    .vip__prices__row__title {
      background-color: #ffffff;
    }

    .vip__prices__row__discount {
      background-color: #937648;
    }

    .vip__prices__row__value {
      background-color: #ffffff;
    }
  }
}

.vip {
  &__description {
    &__header-button {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);

      @include screen-sm-max {
        position: relative;
      }

      &.is-centered {
        left: 50%;
        right: auto;
        transform: translate(-50%, -50%);
      }

      &__wrapper {
        position: relative;
        min-height: 50px;
      }
    }

    &__image {
      height: 100%;
      object-fit: contain;
      margin-inline: -37px;
      width: calc(100% + 74px);

      @include screen-md-max {
        width: calc(100% + 62px);
      }
    }

    &__icon {
      position: absolute;
      left: 40px;
      top: 50%;
      transform: translateY(-50%);
      width: 230px;
      height: 220px;
      margin-inline: -37px;

      @include screen-md-min {
        left: 76px;
        max-height: unset;
      }


      @include screen-md-max {
        width: 115px;
        height: 110px;
      }

      @include screen-sm-max {
        left: 30px;
        width: 80px;
        height: 60px;
      }
    }

    &__box {
      width: 190px;
      height: 115px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 10px;
      border-radius: 4px;

      &.is-red {
        background-color: #b91313;

      }

      &.is-gold {
        background-color: #937648;
      }

      &.is-light {
        background-color: #ecf5fc;
        border: 2px solid #0a173e;

      }
    }
  }

  &__section {
    margin-top: 51px;
    margin-bottom: 55px;
    padding: 0 0 43px;
    text-align: center;

    &__header {
      color: $primary;
      background-color: #fff;
      padding: 24px 10px 20px;
      border-bottom: 1px solid rgba(112, 112, 112, 0.27);
    }
  }

  &__prices {
    &__header {
      align-items: center;
      width: 100%;

      @include screen-sm-max {
        display: none !important;
      }

      &__vip {
        padding: 10px 0;
        background-color: #947748;
      }
    }

    &__section {
      writing-mode: tb;
      text-orientation: mixed;
      transform: rotate(180deg);
      height: min-content;
      display: flex;
      align-items: center;
      width: 45px;

      @include screen-sm-max {
        writing-mode: initial;
        text-orientation: initial;
        transform: initial;
        border-bottom: 1px solid rgba(112, 112, 112, 0.27);
        width: 100%;
        padding: 10px 0;
        display: block;
        margin: 0 auto;
      }

      &__wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;

        @include screen-sm-max {
          flex-direction: column;
        }
      }
    }

    &__footer {
      background-color: #f5f5f5;
      padding-left: 45px;
      display: flex;
      align-items: center;
    }
  }
}

.vip__prices__section__wrapper {
  @include row-colors(odd, even);

  &.vip__prices__row__additional_house_facilities {
    @include row-colors(even, odd);
  }

  &.vip__prices__row__additional_resort_facilities {
    @include row-colors(even, odd);
  }

  .vip__prices__row {
    .vip__prices__row__title {
      display: flex;
      align-items: center;
    }

    .vip__prices__row__discount {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .vip__prices__row__value {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
