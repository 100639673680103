$widthMouse: 30px;
$heightMouse: 48px;
$borderMouse: 4px;

$sizeTrackball: 6px;
$posTrackball: 10px;
$shrinkTrackball: 0.4;

.scroll {
  border: 2px solid rgba(255, 255, 255, 0.5);
  position: relative;
  width: $widthMouse;
  height: $heightMouse;
  border-radius: 100px;
  margin-bottom: 0;

  &:after {
    content: '';
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: $sizeTrackball;
    height: $sizeTrackball;
    border-radius: 100%;
    animation: trackBallSlide 5s linear infinite;
  }
}

@keyframes trackBallSlide {
  0% {
    opacity: 1;
    transform: scale(1) translateY(-$posTrackball);
  }

  6% {
    opacity: 1;
    transform: scale(0.9) translateY(calc($posTrackball/4));
  }

  14% {
    opacity: 0;
    transform: scale($shrinkTrackball) translateY($posTrackball * 2);
  }

  15%,
  19% {
    opacity: 0;
    transform: scale($shrinkTrackball) translateY(-$posTrackball);
  }

  28%,
  29.99% {
    opacity: 1;
    transform: scale(1) translateY(-$posTrackball);
  }

  30% {
    opacity: 1;
    transform: scale(1) translateY(-$posTrackball);
  }

  36% {
    opacity: 1;
    transform: scale(0.9) translateY(calc($posTrackball/4));
  }

  44% {
    opacity: 0;
    transform: scale($shrinkTrackball) translateY($posTrackball * 2);
  }

  45%,
  49% {
    opacity: 0;
    transform: scale($shrinkTrackball) translateY(-$posTrackball);
  }

  58%,
  59.99% {
    opacity: 1;
    transform: scale(1) translateY(-$posTrackball);
  }

  60% {
    opacity: 1;
    transform: scale(1) translateY(-$posTrackball);
  }

  66% {
    opacity: 1;
    transform: scale(0.9) translateY(calc($posTrackball/4));
  }

  74% {
    opacity: 0;
    transform: scale($shrinkTrackball) translateY($posTrackball * 2);
  }

  75%,
  79% {
    opacity: 0;
    transform: scale($shrinkTrackball) translateY(-$posTrackball);
  }

  88%,
  100% {
    opacity: 1;
    transform: scale(1) translateY(-$posTrackball);
  }
}
