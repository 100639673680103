.booking-offers {
  &__item {
    position: relative;

    &.inactive {
      pointer-events: none;
      user-select: none;

      .booking-offers__inactive-banner {
        left: 0;
        opacity: 1;
      }
    }
  }

  &__inactive-banner {
    z-index: 2;
    background-color: rgba(87, 96, 103, 0.8);
    opacity: 0;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: -10px;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    transition: left 200ms linear, opacity 200ms linear;
  }
}
