.error-page {
  &__wrapper {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: white;
    z-index: 10;
    overflow: hidden;
  }

  &__content {
    width: 65%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    @include fadeInAnimation;

    @media (max-width: 1199px) {
      width: 80%;
    }
  }

  &__logo-section {
    white-space: nowrap;
    margin-top: 120px;
    display: flex;
    flex-direction: column;

    @media (max-width: 1199px) {
      margin-top: 50px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      max-width: 90%;
      white-space: pre-wrap;
      text-align: center;
    }
  }

  &__logo {
    margin-bottom: 40px;
    height: 40px;
    width: 145px;


    @media (max-width: 1199px) {
      margin: 0 auto 40px auto;
    }
  }

  &__text {
    font-size: 20px;
    color: #656565;

    &--bold {
      color: #434343;
    }
  }

  &__button {
    margin-top: 20px;
    background: linear-gradient(#606a72, #444c52);
    padding: 14px 30px;
    color: white;
    font-weight: bold;
    font-size: 14px;
    border-radius: 4px;
    border: none;
    min-width: 200px;

    &:hover {
      color: rgba(255, 255, 255, 0.65);
    }

    @media (max-width: 767px) {
      max-width: 500px;
      width: 100%;
    }
  }

  &__image {
    margin-top: 80px;

    @media (max-width: 1199px) {
      margin-top: 0;
      left: 50%;
      transform: translateX(-50%);
      opacity: 0.1;
      z-index: -1;
      position: absolute;
      width: 100vh;
    }
  }

  &__number-msg {
    color: #B99153FF;
    display: block;
    font-size: 20px;
    top: 24px;
    left: 56px;
    position: absolute;
    text-shadow: 1px 0 #B99153FF;
    letter-spacing: 2px;
    font-weight: bold;
  }

  &__number {
    display: block;
    margin-bottom: 40px;
    font-size: 140px;
    color: #0f2453;
    letter-spacing: 8px;
    text-shadow: 5px 0 #0f2453;

    @media (max-width: 1769px) {
      margin-bottom: 20px;
      font-size: 130px;
      width: 310px;
    }

    &__shadow {
      position: absolute;
      font-size: 140px;
      color: white;
      text-shadow: -1px 0 #0f2453, 0 1px #0f2453, 1px 0 #0f2453, 0 -1px #0f2453;
      top: 2px;
      left: 8px;
      z-index: -1;
      letter-spacing: 8px;

      @media (max-width: 1769px) {
        font-size: 130px;
      }

      @media (max-width: 1199px) {
        top: 18px;
        left: 16px;
      }
    }
  }

  &__number-wrapper {
    position: relative;
    margin-top: 50px;
  }
}


@mixin fadeInAnimation {
  animation: fadeIn 300ms linear forwards;
}

@mixin fadeOutAnimation {
  animation: fadeOut 300ms linear forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    filter: blur(2px);
  }

  100% {
    opacity: 1;
    filter: none;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    filter: none;
  }

  100% {
    opacity: 0;
    filter: blur(2px);
  }
}
