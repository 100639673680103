.mobile-menu {
  position: relative;
  display: none;
  margin-top: -50px;

  a:active {
      color: $gold;
  }

  @include screen-md-max {
    display: block;
  }

  .accordion-button {
    &:after {
      width: 1.5rem;
      height: 1.5rem;
      background-size: 1.5rem;
    }

    &:not(.collapsed) {
      color: $darker-gray;
      box-shadow: none;
      background-color: initial;
    }

    &:focus {
      box-shadow: none;
      border-color: initial;
    }
  }

  .accordion-body {
    padding-block: 0;

    li {
      font-size: $font-xl;
      padding-top: 0;
      padding-bottom: 10px;
      padding-left: 10px;

      &:active {
        color: $gold;
      }
    }
  }

  &__accordion-link {
    color: $darker-gray;
    font-size: $font-xl;
    font-weight: 500;
    left: 15px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;

    &:active {
      color: $gold;
    }
  }
}
