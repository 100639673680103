html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  background-color: $light-azure !important;
  font-size: 13px;
  font-family: museo_sans, sans-serif !important;
  color: $darker-gray;
  scroll-padding-top: 100px;

  @include screen-xl-min {
    scroll-padding-top: 150px;
  }

  @include screen-xl-min {
    font-size: 14.5px;
  }

  @include screen-xs-min {
    font-size: 12.5px;
  }

  @include screen-md-max {
    &.block-scroll {
      overflow: hidden;
      touch-action: none;
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
    }
  }

  @include screen-md-max {
    background-color: white !important;
  }
}

.bg-light-gray {
  background-color: $light-gray;
}

.bg-azure {
  background-color: $light-azure !important;
}

.bg-gold {
  background-color: $gold;
}

.bg-gold-dark {
  background-color: $gold-dark;
}

.bg-alice-blue {
  background-color: $alice-blue;
}

.bg-silver {
  background-color: $silver;
}

.bg-brown {
  background-color: $brown;
}

.cursor-pointer {
  cursor: pointer;
}

.font-size-xxs {
  font-size: $font-xxs;
}
.font-size-xs {
  font-size: $font-xs;
}

.font-size-sm {
  font-size: $font-sm;
}

.font-size-md {
  font-size: $font-md;
}

.font-size-lg {
  font-size: $font-lg;
}

.font-size-xl {
  font-size: $font-xl;
}

.font-size-xxl {
  font-size: $font-xxl;
}

.fw-semi-bold {
  font-weight: 500;
}

.underline-shadow {
  background-size: 1px 1em;
  box-shadow: inset 0 0 white, inset 0 -0.4em rgba($gold, 0.3);
  display: inline;
}

.text-gold {
  color: $gold;
}

.text-gold-dark {
  color: $gold-dark;
}

.text-blue {
  color: $dark-blue;
}

.text-red-dark {
  color: $darker-red;
}

.text-gray-dark {
  color: $darker-gray;
}

.text-anthracite {
  color: $anthracite;
}

.text-black-light {
  color: $light-black;
}

.content {
  padding-top: 65px;

  & > .content {
    padding-top: 0;
  }
}

.section-content {
  padding-top: 75px;
  padding-bottom: 75px;

  @include screen-md-max {
    padding-block: 35px;
  }

  &.is-smaller-padding {
    padding-top: 35px;
  }
}

.hr-short-underline {
  margin: 25px auto;
  height: 3px !important;
  width: 184px;
  background-color: $gold-dark;
  opacity: 1;
}

.hr-long-underline {
  background-color: #adadad;
  height: 2px !important;
  margin: 32px 0;
}

.strikeout {
  position: relative;

  &:after {
    position: absolute;
    content: '';
    height: 1px;
    background-color: $darker-red;
    width: 110%;
    transform: rotate(-14deg);
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.form-check-input {
  min-height: 20px;
  min-width: 20px;
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.form-check-label {
  color: $darker-gray;
  font-size: 11px;
  cursor: pointer;

  a {
    font-weight: bold;
  }
}

.object-fit-cover {
  object-fit: cover;
}

.btn-secondary:hover {
  border-color: $gold;
  background-color: $gold;
}

.accordion-button:hover {
  z-index: 0;
}
