.ecommerce {
  &__banner {
    height: 400px;
    min-width: 95px;
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 4;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding-top: 45px;
    padding-bottom: 20px;
    padding-inline: 20px;
    background-color: #0a173e;
    transition: right 0.5s linear;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include screen-md-max {
      padding: 11px;
      height: auto;
      min-width: auto;
      top: 30%;
      z-index: 2;

      .ecommerce__banner__arrow {
        display: none;
      }

      .ecommerce__banner__icon-left {
        top: 45px;
        left: 5px;
      }

      .ecommerce__banner__icon-right {
        top: 18px;
        right: 6px;
      }
    }

    &.is-viewed {
      display: none;
    }

    &__icon-main {
      font-size: 45px;
      color: white;
      animation: iconAnimation 3s infinite;
    }

    &__icon-right {
      position: absolute;
      right: 11px;
      top: 65px;
      rotate: 12deg;
      font-size: 13px;
      color: white;
      opacity: 30%;
      animation: rightIconAnimation 3s infinite;
    }

    &__icon-left {
      color: white;
      opacity: 20%;
      font-size: 11px;
      position: absolute;
      left: 18px;
      top: 80px;
      rotate: -9deg;
      animation: leftIconAnimation 3s infinite;
    }

    &__arrow {
      font-size: 45px;
      color: white;
      margin-top: auto;
    }

    &__background {
      position: absolute;
      left: 0;
      top: 0;
      object-fit: cover;
      height: 100%;
      width: 100%;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      opacity: 0.1;
      z-index: 0;
    }
  }
}

@keyframes iconAnimation {
  0% {
    opacity: 100%;
    rotate: -9deg;
  }
  50% {
    opacity: 30%;
    rotate: -35deg;
  }
  100% {
    opacity: 100%;
    rotate: -9deg;
  }
}

@keyframes rightIconAnimation {
  0% {
    rotate: -9deg;
  }
  50% {
    rotate: -35deg;
  }
  100% {
    rotate: -9deg;
  }
}

@keyframes rightIconAnimation {
  0% {
    rotate: 12deg;
  }
  50% {
    rotate: 45deg;
  }
  100% {
    rotate: 12deg;
  }
}
