.newsletter {
  &__confirm {
    &__image {
      height: 350px;
      object-fit: cover;
      width: 100%;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
  }
}
