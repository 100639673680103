@mixin fadeInAnimation {
  animation: fadeIn 300ms linear forwards;
}

@mixin fadeOutAnimation {
  animation: fadeOut 300ms linear forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    filter: blur(2px);
  }

  100% {
    opacity: 1;
    filter: none;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    filter: none;
  }

  100% {
    opacity: 0;
    filter: blur(2px);
  }
}
