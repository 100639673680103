#special-offer-carousel {
  .carousel__button.is-next {
    right: -40px;
    display: none;
  }

  .carousel__button.is-prev {
    left: -40px;
    display: none;
  }

  @include screen-md-min {
    .carousel__dots {
      display: none;
    }

    .carousel__button.is-next,.carousel__button.is-prev {
      display: block;
    }
  }
}
