.resorts {
  &__filter-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    border-right: 1px solid rgba(112, 112, 112, .31);

    @include screen-md-max {
      width: 100%;
      border-right: none;
      flex-direction: column;
      height: initial;
      align-items: flex-start;
      padding: 0;
    }
  }

  &__filter-backdrop {
    opacity: 0;
    pointer-events: none;
    position: fixed;
    height: 100vh;
    width: 100vw;
    left: 0;
    top: 0;
    background-color: #576067;
    z-index: 1;
    transition: opacity .3s ease;

    &.is-open {
      opacity: 0.8;
      pointer-events: initial;
    }
  }

  &__filter-header,
  &__filter-input {
    @include screen-md-max {
      width: 100%;
      font-weight: 700;
      border-bottom: 1px solid rgb(222, 226, 230);
    }
  }

  &__filter-header {
    margin-right: 37px;

    @include screen-md-max {
      padding: 20px 30px 15px;
      font-size: 15px;
      margin-right: 0;
    }
  }

  &__filter-input {
    margin-right: 18px;

    @include screen-md-max {
      padding: 15px 30px !important;
      font-size: 14px;
      margin-right: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      @include screen-md-max {
        background-color: #EFF3F7;
      }
    }

    &:after {
      @include screen-md-max {
        left: 35px !important;
        top: 24px !important;
      }
    }
  }

  &__filter-native-input {
    &:checked + .resorts__filter-input {
      @include screen-md-max {
        color: $gold;
      }
    }
  }

  &__filter-clear-btn {
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    background: #fff;
    border: none;

    @include screen-md-max {
      text-transform: none;
      color: #fff;
      background: linear-gradient(#606a72,#444c52);
      width: 100%;
      height: 56px;
    }
  }

  &__filter-btn {
    display: none;

    @include screen-md-max {
      display: block;
      width: 100%;
      padding: 9px 0;
    }
  }

  &__filter-wrapper {
    flex-direction: row;
    align-items: center;

    @include screen-md-max {
      flex-direction: column;
      transition: transform .3s ease;
      transform: translateY(100%);
      position: fixed;
      bottom: 0;
      left: 9px;
      width: 100%;
      background-color: #fff;
      z-index: 1;
      border-top-left-radius: 23px;
      border-top-right-radius: 23px;
    }

    &.is-open {
      @include screen-md-max {
        transform: translateY(0%);
      }
    }
  }

  &__small-cards-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    @include screen-lg-max {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include screen-md-max {
      display: none;
    }
  }

  &__big-cards-section {
    @include screen-md-max {
      padding-top: 0;
    }
  }

  &__big-cards-wrapper {
    background: #fff;
    display: flex;
    box-shadow: 0 3px 20px -7px rgba(0, 0, 0, 0.28);
    border-radius: 4px;
    margin-bottom: 50px;

    @include screen-md-max {
      flex-direction: column;
      margin-bottom: 26px;
    }
  }

  &__big-card-img {
    min-width: 523px;
    height: auto;

    @include screen-lg-max {
      min-width: 400px;
    }

    @include screen-md-max {
      width: 100%;
      min-width: initial;
      height: 250px;
    }
  }

  &__big-card-header {
    padding: 21px 32px;
    font-size: 15px;
    border-bottom: 1px solid #707070;

    @include screen-md-max {
      display: none;
    }
  }

  &__big-card-distance-box {
    padding: 4px 17px;
    background-color: rgba(0, 0, 0, 0.1)
  }

  &__big-card-content {
    padding: 11px 32px 59px;
    overflow: hidden;

    @include screen-md-max {
      padding: 20px 18px 59px;
    }
  }

  &__big-card-resort-header {
    font-size: 44px;
    color: $dark-blue;
    font-weight: 700;

    @include screen-md-max {
      font-size: 20px;
    }
  }

  &__big-card-social-icon {
    height: 15px;
    width: auto;
  }

  &__big-card-attractions {
    padding: 16px 27px;
    background-color: #eff3f7;

    @include screen-md-max {
      padding: 0;
      background-color: #fff;
    }

    &__elements {
      display: flex;
      flex-wrap: wrap;

      @include screen-md-max {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
      }

      @include screen-sm-max {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  &__big-card-map {
    width: 100%;
    height: 167px;
    background-color: #eff3f7;

    @include screen-md-max {
      display: none;
    }
  }

  &__big-card-footer {
    position: absolute;
    bottom: 0;
    left: 32px;

    @include screen-md-max {
      left: 18px;
    }
  }

  &__trapezoid-button {
    padding-left: 40px;
    padding-right: 40px;

    @include screen-md-max {
      padding: 10px 20px;
    }
  }

  &__big-card-mobile-social {
    display: none;

    @include screen-md-max {
      display: flex;
      align-items: center;
      position: absolute;
      background: #fff;
      right: 19px;
      bottom: -22px;
      border-radius: 4px;
    }
  }
}
