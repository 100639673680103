.reservation-calendar {
  &.is-open-down {
    box-shadow: 0px -1px 2px 0px rgba(66, 68, 90, 0.1);

    .rdrCalendarWrapper {
      flex-direction: column;
    }

    .date-range-footer {
      position: relative;
      width: auto;
      bottom: auto;
    }

    .date-range-wrapper {
      display: block;
    }

    .date-range__year-select {
      order: initial;
    }

    .date-range__type-select {
      order: initial;
      margin-top: 0;
    }

    .date-range__year-select + div.d-flex {
      order: initial;
    }

    .main-page__reservation-wrapper {
      background-color: transparent;
      position: relative;
      height: auto;
      padding: 0;
      z-index: 3;
    }

    .main-page__reservation-box {
      position: relative;
      box-shadow: none;
      border: none;
    }
  }
}
