.holidaypark-logo-colorized {
  height: 40px;
  width: 130px;
  background: url('../images/logo_holiday_color.svg');
}

.holidaypark-logo {
  background: url('../images/logo_holiday.svg');
}

.holidaypark-logo-white {
  height: 40px;
  width: 130px;
  background: url('../images/logo_holiday_white.svg');
}

.logo-holiday-gray {
  height: 40px;
  width: 140px;
  background: url('../images/lead-generation-form/logo-holiday-gray.svg');
}

.holidaypark-logo-sygnet__wrapper {
  width: 34px;
  height: 34px;
}

.holidaypark-logo-sygnet {
  width: 34px;
  height: 34px;
  background-size: contain;
  background: url('../images/logo_sygnet.svg');
}

.holidaypark-logo-sygnet-white {
  width: 50px;
  height: 50px;
  background-size: contain;
  background: url('../images/logo_sygnet_white.svg');
}

.arrow-top {
  height: 30px;
  width: 30px;
  background: url('../images/arrow-top.svg');
}

.arrow-top-gold {
  height: 30px;
  width: 30px;
  background: url('../images/arrow-top-gold.svg');
}

.arrow-right {
  height: 10px;
  width: 24px;
  background: url('../images/arrow-right.svg');
}

.fb {
  height: 30px;
  width: 30px;
  background: url('../images/fb.svg');
}

.instagram {
  height: 30px;
  width: 30px;
  background: url('../images/instagram.svg');
}

.youtube {
  height: 30px;
  width: 30px;
  background: url('../images/youtube.svg');
}

.footer-logo-shape {
  height: 1000px;
  width: 100vw;
  background: url('../images/footer-logo-shape.svg');
}

.checkmark {
  height: 22px;
  width: 22px;
  background: url('../images/checkmark.svg');
}

.crossmark {
  height: 19px;
  width: 19px;
  background: url('../images/crossmark.svg');
}

.vip-white {
  height: 38px;
  width: 38px;
  background: url('../images/vip-white.svg');
}

.honesty {
  height: 49px;
  width: 68px;
  background: url('../images/icons/honesty.svg');
}

.sales {
  height: 53px;
  width: 48px;
  background: url('../images/icons/sales.svg');
}

.people {
  height: 49px;
  width: 64px;
  background: url('../images/icons/people.svg');
}

.house {
  height: 52px;
  width: 52px;
  background: url('../images/icons/house.svg');
}

.document {
  height: 53px;
  width: 52px;
  background: url('../images/icons/document.svg');
}

.instruction {
  height: 52px;
  width: 39px;
  background: url('../images/icons/instruction.svg');
}

.gift {
  height: 52px;
  width: 64px;
  background: url('../images/icons/gift.svg');
}

.career-1 {
  height: 52px;
  width: 64px;
  background: url('../images/career/career-1.svg');
}

.career-2 {
  height: 52px;
  width: 64px;
  background: url('../images/career/career-2.svg');
}

.career-3 {
  height: 52px;
  width: 64px;
  background: url('../images/career/career-3.svg');
}

.career-4 {
  height: 52px;
  width: 64px;
  background: url('../images/career/career-4.svg');
}

.career-5 {
  height: 52px;
  width: 64px;
  background: url('../images/career/career-5.svg');
}

.weather-sun-icon {
  height: 60px;
  width: 54px;
  background: url('../images/weather-live.svg') no-repeat;
}

.water-playground {
  height: 35px;
  width: 35px;
  background: url('../images/icons/water-playground.svg');
}

.bestseller {
  background: url('../images/bestseller.svg') no-repeat;
  height: 40px;
  width: 150px;
  max-width: 100%;
}

.call-center {
  fill: $primary;
  height: 55px;
  width: 55px;
  background: url('../images/icons/call-center.svg');
}

.hand {
  fill: $primary;
  height: 55px;
  width: 55px;
  background: url('../images/icons/hand.svg');
}

.form {
  fill: $primary;
  height: 55px;
  width: 55px;
  background: url('../images/icons/form.svg');
}

.offer {
  fill: $primary;
  height: 55px;
  width: 55px;
  background: url('../images/icons/offer.svg');
}

.live-cooking {
  fill: $primary;
  height: 65px;
  width: 65px;
  background: url('../images/icons/live-cooking.svg');
}

.caffe-break {
  fill: $primary;
  height: 65px;
  width: 65px;
  background: url('../images/icons/caffe-break.svg');
}

.dinner {
  fill: $primary;
  height: 65px;
  width: 65px;
  background: url('../images/icons/dinner.svg');
}

.jacuzzi {
  fill: $primary;
  height: 65px;
  width: 65px;
  background: url('../images/icons/jacuzzi.svg');
}

.gym {
  fill: $primary;
  height: 65px;
  width: 65px;
  background: url('../images/icons/gym.svg');
}

.swimming-pool {
  fill: $primary;
  height: 65px;
  width: 65px;
  background: url('../images/icons/swimming-pool.svg');
}

.star {
  height: 20px;
  width: 20px;
  background: url('../images/icons/star.svg');
}

.conference {
  height: 70px;
  fill: white;
  background: url('../images/icons/conference.svg');
}

.vr-saloon {
  height: 45px;
  width: 45px;
  background: url('../images/icons/vr-saloon.svg');
}

.holiday-kids-club {
  height: 45px;
  width: 45px;
  background: url('../images/icons/holiday-kids-club.svg');
}

.cooking-lessons {
  height: 45px;
  width: 45px;
  background: url('../images/icons/cooking-lessons.svg');
}

.game-console {
  height: 45px;
  width: 45px;
  background: url('../images/icons/game-console.svg');
}

.dj {
  height: 45px;
  width: 45px;
  background: url('../images/icons/dj.svg');
}

.chillout-room {
  height: 45px;
  width: 45px;
  background: url('../images/icons/chillout-room.svg');
}

.bike-rent {
  height: 45px;
  width: 45px;
  background: url('../images/icons/bike-rent.svg');
}

.inflatables {
  height: 45px;
  width: 45px;
  background: url('../images/icons/inflatables.svg');
}

.resort-map-icon {
  height: 30px;
  width: 78px;
  background: url('../images/icons/resort-map-icon.svg');
}

.christmas-eve {
  height: 60px;
  fill: white;
  width: 60px;
  background: url('../images/icons/christmas-eve.svg');
}

.corporate-picnic {
  height: 60px;
  fill: white;
  width: 60px;
  background: url('../images/icons/corporate-picnic.svg');
}

.courses {
  height: 60px;
  fill: white;
  width: 60px;
  background: url('../images/icons/courses.svg');
}

.everything {
  height: 60px;
  fill: white;
  width: 60px;
  background: url('../images/icons/everything.svg');
}

.family-events {
  height: 60px;
  fill: white;
  width: 60px;
  background: url('../images/icons/family-events.svg');
}

.integration-party {
  fill: white;
  height: 60px;
  width: 60px;
  background: url('../images/icons/integration-party.svg');
}
