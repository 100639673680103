.tourist-voucher {
  &__section {
    margin-top: 51px;
    margin-bottom: 55px;
    padding: 0 0 43px;
    text-align: center;

    &__header {
      color: $primary;
      background-color: #fff;
      padding: 24px 24px 20px;
      border-bottom: 1px solid rgba(112, 112, 112, 0.27);

      &.is-full-width {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include screen-sm-max {
          flex-direction: column;
        }
      }
    }
  }

  &__gallery {
    &__image {
      height: 500px;
      width: 100%;
      object-fit: cover;
      object-position: top;
    }
  }
}