.main-page {
  &__video {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__jumbotron {
    color: $anthracite;
    width: 100%;
    height: 100vh;
    margin-top: -65px;
    display: flex;
    flex-direction: column;
    font-size: 13px;
  }

  &__reservation-overlay {
    &:after {
      content: '';
      position: fixed;
      height: 100vh;
      width: 100vw;
      top: 0;
      left: 0;
      background-color: #000000b0;
      z-index: 2;
      pointer-events: none;
      opacity: 0;
      transition: opacity 200ms linear;
    }

    &.is-visible {
      &:after {
        pointer-events: auto;
        opacity: 1;
      }
    }
  }

  &__reservation {
    margin-top: -150px;
    height: 150px;
  }

  &__reservation-wrapper {
    z-index: 3;
    padding: 39px 100px;
    width: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    height: 150px;

    &.no-localization {
      .rdrCalendarWrapper {
        border-left: none;
      }
    }

    @include screen-lg-max {
      padding-left: 30px;
      padding-right: 30px;
    }

    @include screen-md-max {
      background: none;
    }

    &.is-hidden {
      .rdrCalendarWrapper > *:not(.date-range__type-select) {
        display: none !important;
      }

      .date-range-footer {
        display: none !important;
      }

      .date-range__type-select {
        margin-top: 0;
      }
    }

    .date-range__type-select {
      height: 69px;
      margin-top: 43px;
      border-bottom: none;
    }

    .date-range__year-select {
      order: 2;

      & + div.d-flex {
        order: 1;
      }
    }

    .date-range__date-box,
    .date-range__type {
      cursor: pointer;
    }

    .rdrCalendarWrapper {
      display: flex;
      flex-direction: column-reverse;

      @include screen-lg-min {
        border-right: 1px solid #dee2e6;
        border-left: 1px solid #dee2e6;
      }
    }

    .date-range-footer {
      position: absolute;
      width: 100%;
      bottom: 70px;
      background-color: white;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;

      @include screen-lg-min {
        border-top: 1px solid #dee2e6;
      }

      .trapezoid__button {
        background-color: #b91313;

        &:before {
          left: -19px;
          border-bottom-color: #b91313;
        }
      }
    }
  }

  &__reservation-box-mobile {
    position: fixed;
    z-index: 2;
    left: 0;
    bottom: 0;
    width: 100%;
    box-shadow: 0px 3px 18px rgba(0, 0, 0, 0.4);
  }

  &__reservation-box {
    position: fixed;
    left: 0;
    right: 0;
    box-shadow: 0px 3px 18px rgba(0, 0, 0, 0.4);
    border: 0;
    display: flex;
    height: 71px;
    padding: 0;

    &.no-localization {
      width: 70vw;

      @include screen-xl-min {
        width: 55vw;
      }
    }

    .date-range-wrapper {
      width: 50%;
      display: flex;
      flex-direction: column-reverse;
      position: relative;
    }
  }

  &__reservation-box-el {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: auto;
    padding: 15px 18px;
    text-align: center;

    @include screen-md-max {
      display: none;
    }

    &:last-child {
      border-right: none;

      @include screen-md-max {
        display: block;
      }
    }
  }

  &__button {
    font-size: 14px;
    font-weight: 600;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__icon {
    font-size: 22px;
    margin-right: 7px;
  }

  &__arrow-right-icon {
    font-size: 25px;
  }

  &__special-offer-newsletter-text {
    margin: 30px 0;
    line-height: 18px;
  }

  &__special-offer-newsletter-input {
    padding: 13px 17px;
    color: $darker-gray;
    font-size: 12px;
    box-shadow: 0px 3px 18px rgba(0, 0, 0, 0.1);
    border: 1px solid #9099a1;
    border-radius: 4px;
    margin-top: auto;

    &::placeholder {
      color: $darker-gray;
      font-size: 12px;
    }
  }

  &__special-offer-newsletter-btn {
    width: 100%;
    margin-top: 9px;
    color: #fff;
    background-color: $gold-dark;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    padding: 12px;

    &:hover {
      color: #fff;
      background-color: $darker-red;
    }
  }

  &__resort-card {
    border-radius: 4px;

    &--with-shadow {
      box-shadow: 0 3px 20px -7px rgba(0, 0, 0, 0.28);
    }
  }

  &__resort-card-content {
    position: relative;
    overflow: hidden;
    padding-top: 15px;
    padding-bottom: 40px;
    height: 100%;

    @include screen-md-max {
      border-top: none;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &__attraction {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }

    @include screen-md-max {
      width: 50%;
      margin-right: 0;
    }
  }

  &__opinion-wrapper {
    @include screen-lg-max {
      overflow: auto;
    }
  }

  &__opinion {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 3px 18px rgba(0, 0, 0, 0.08);
    padding: 32px 28px 20px 23px;
    width: 280px;
    margin-right: 20px;
    flex-direction: column;
    justify-content: space-between;
    display: flex;

    @include screen-lg-max {
      flex-shrink: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__company-section {
    max-width: 968px;
  }

  &__company-image {
    height: 100%;
  }
}
