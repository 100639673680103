.restaurant {
  &__section-content {
    padding: 40px 100px 0;

    @include screen-md-max {
      padding: 30px 15px 0;
    }

    .gallery {
      padding: 0;
    }

    &>.gallery {
      margin-top: -40px;
      margin-bottom: 48px;
      margin-inline: -100px;

      @include screen-sm-max {
        margin-bottom: -12px;
        margin-top: -36px;
      }

      @include screen-md-max {
        margin-inline: -15px;
      }
    }
  }

  &__table {
    margin-top: 29px;
  }

  &__table-header,
  &__table-row {
    border-bottom: 1px solid rgba(182, 204, 221, 0.5);

    @include screen-md-max {
      border-bottom: none;
    }
  }

  &__table-row {
    @include screen-md-max {
      border-top: 1px solid rgba(182, 204, 221, 0.5);
    }

    &:first-of-type {
      @include screen-md-max {
        border-top: none;
      }
    }

    &:last-of-type {
      border-bottom: none;
    }
  }

  &__table-header-cell {
    padding: 19px 5px !important;
    vertical-align: middle;

    @include screen-md-max {
      width: 33%;
    }

    &:first-child {
      @include screen-md-max {
        display: none;
      }
    }
  }

  &__table-cell {
    padding: 10px 5px !important;
    vertical-align: middle;

    @include screen-md-max {
      width: 33%;
    }

    &.is-full-width {
      @include screen-md-max {
        width: 100%;
      }
    }
  }

  &__table-label {
    position: absolute;
    top: -12px;
    background-color: #e9f4eb;
    border: 1px solid rgba(44, 143, 65, 0.4);
    border-radius: 5px;
    color: $green-pearl;
    padding: 0px 20px 0 3px;
    line-height: 0px;
    display: inline-flex;
    align-items: center;
    left: 50%;
    transform: translateX(-50%);
    width: max-content;

    @include screen-md-max {
      display: none;
    }
  }
}

.restaurant-prices {
  color: #21254b;
  font-size: 23px;
  line-height: 1.3;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  .row {
    margin-inline: 0;
  }

  &__icon {
    width: 40px;
    height: 40px;

    @include screen-md-max {
      width: 20px;
      height: 20px;
    }

    &.is-bestseller {
      height: 40px;
      width: 150px;
      max-width: 100%;

      @include screen-md-max {
        height: 20px;
        width: 100%;
      }
    }
  }

  @include screen-md-max {
    font-size: 12px;
  }

  @include screen-xs-max {
    font-size: 11px;
  }

  &__border {
    position: relative;

    &.is-bestseller {
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 10px;
        width: calc(100% - 30px);
        height: 1px;
        border-bottom: 1px dashed white;

        @include screen-md-max {
          width: calc(100% - 10px);
          left: 5px;
        }
      }
    }

    &.is-row {
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: -10px;
        width: calc(100% - 2px);
        height: 1px;
        border-bottom: 1px dashed #5ca070;

        @include screen-md-max {
          left: -3px;
        }
      }
    }
  }

  &__bestseller__header {
    background-color: #e84e1c;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__hours {
    color: #5ca070;
    font-weight: 700;
    font-size: 19px;
    display: block;
    white-space: nowrap;

    @include screen-md-max {
      font-size: 11px;
    }

    @include screen-xs-max {
      font-size: 10px;
    }
  }

  &__bestseller__box,
  &__guest__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 23px;
    font-weight: bold;
    padding: 12px;

    @include screen-md-max {
      font-size: 11px;
      padding: 8px;
    }
  }

  &__bestseller__box {
    background-color: #f18f73;

    &__price__wrapper {
      position: relative;
      text-align: center;
      width: 100%;
      display: inline-block;

      &:after {
        content: '';
        position: absolute;
        bottom: -18px;
        width: calc(100% - 4px);
        left: -3px;
        height: 1px;
        border-bottom: 1px dashed white;

        @include screen-md-max {
          bottom: -9px;
          left: 0;
        }
      }
    }
  }

  &__guest__box {
    background-color: #c3d670;

    &__price {
      background-color: whitesmoke;
      border-radius: 4px;
      padding-block: 10px;
      min-width: 145px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 41px;
      line-height: 40px;
      font-weight: bold;
      width: fit-content;
      margin: 10px auto 0;

      @include screen-md-max {
        font-size: 12px;
        min-width: 50px;
        padding-block: 3px;
        margin-top: 5px;
      }
    }
  }
}