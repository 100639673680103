.vouchers {
  &__section {
    margin-top: 51px;
    margin-bottom: 55px;
    padding: 0 0 43px;
    text-align: center;

    @include screen-md-max {
      padding: 0;
      margin-top: 0;
    }

    &__header {
      color: $primary;
      background-color: #fff;
      padding: 24px 10px 20px;
      border-bottom: 1px solid rgba(112, 112, 112, 0.27);

      &.is-light-gray {
        background-color: #fcfcfc;
      }
    }

    .carousel {
      &__slide {
        width: 25%;

        img {
          max-width: 100%;
        }
      }

      &__button {
        display: none;
      }
    }
  }

  &__description {
    &__leaflet-image {
      max-width: 100%;
      margin-top: 30px;

      @include screen-md-min {
        margin-left: auto;
        margin-top: 0;
      }
    }
  }

  &__counts {
    &__image {
      margin-top: -2px;
      width: 100%;
      height: calc(100% + 2px);
      object-fit: cover;
      max-height: 350px;
    }
  }

  &__practical-information {
    &__text {
      max-width: 200px;
      font-weight: 300;
      margin-left: 10px;
    }
  }

  &__special-dates {
    &__table {
      & > :not(:first-child) {
        border: none;
      }

      th {
        padding: 12px 0;
        background-color: #937648;
        font-weight: 500;
        color: white;
        border: none;
      }

      td {
        color: $darker-gray;
        padding: 10px 0;
        font-weight: 500;
        border: none;
      }

      tr:nth-child(even) {
        background-color: #f5f5f5;
      }

      tr:nth-child(odd) {
        background-color: white;
      }
    }
  }

  &__terms-button {
    font-size: 12px;
    padding: 12px 24px;
    color: white;
    font-weight: 700;

    &:hover {
      color: white;
    }

    @include screen-md-max {
      width: 100%;
      max-width: 600px;
      margin: 0 auto;
    }
  }

  &__partner-contact {
    &__input {
      padding: 11px 14px;
      margin-bottom: 8px;

      &:focus {
        box-shadow: none;
      }
    }

    &__submit {
      display: block;
      font-weight: 700;
      padding: 10px 24px;
      font-size: 12px;

      @include screen-md-max {
        width: 100%;
        max-width: 600px;
        margin: 20px auto;
      }
    }

    &__image {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;

      @include screen-md-max {
        max-height: 450px;
      }
    }
  }
}
