.contact-header {
  padding-top: 60px;
  padding-bottom: 32px;
  color: $dark-blue;

  @include screen-md-max {
    padding-top: 32px;
  }

  &__phone-icon {
    position: relative;
    color: $gold;
    font-size: 43px;
    margin-top: -14px;
    margin-right: 10px;
  }

  &__phone-icon-status {
    position: absolute;
    top: 13px;
    right: -1px;
    width: 6px;
    height: 6px;
    background-color: #0acf97;
    box-shadow: 0px 0px 6px 2px #0acf97;
    border-radius: 50%;
  }
}

.contact-map {
  background-color: #f5fafd;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  &__wrapper {
    margin-top: 65px;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    @include screen-md-max {
      margin-top: 30px;
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include screen-sm-max {
      grid-template-columns: 1fr 1fr;
    }

    @include screen-xs-max {
      grid-template-columns: 1fr;
    }
  }

  &__address {
    margin-bottom: 25px;
  }

  &__image {
    height: 175px;
  }

  &__text {
    border: 1px solid $darker-gray;
    border-top: none;
    border-radius: 4px;
    flex: 1;
  }

  &__header {
    font-weight: 600;
  }
}

.contact-form {
  margin-top: 65px;

  &__header {
    font-size: 20px;
    padding-bottom: 20px;
    margin: -14px -37px 0;
    border-bottom: 1px solid rgba(112, 112, 112, 0.27);

    @include screen-md-max {
      border-bottom: none;
      padding-bottom: 0;
      margin: 0;
    }
  }

  &__form {
    margin: 38px 0;

    @include screen-md-max {
      flex-direction: column;
    }

    &.no-label {
      .form-label {
        display: none;
      }
    }
  }

  &__inputs {
    width: 40%;
    margin-right: 31px;

    @include screen-md-max {
      width: 100%;
    }
  }

  &__input {
    padding: 8px 15px;
    border: 1px solid #d1d1d1;
    border-radius: 4px;
    height: 41px;
    background-color: #f1f4f5;
    letter-spacing: 1px;
    width: 100%;
    margin-bottom: 10px;
    color: $darker-gray;

    &::placeholder {
      color: $darker-gray;
    }

    @include screen-md-max {
      margin-bottom: 14px;
    }

    &:last-child {
      margin-bottom: 0;

      @include screen-md-max {
        margin-bottom: 14px;
      }
    }

    &:focus {
      outline: none;
      border-color: $gold;
      box-shadow: none;
    }
  }

  &__textarea {
    flex: 1 1 auto;
    padding: 18px 17px 16px;
    resize: none;
    border: 1px solid #d1d1d1;
    border-radius: 4px;
    background-color: #f1f4f5;

    &:focus {
      outline: none;
      border-color: $gold;
      box-shadow: none;
    }
  }

  &__terms-paragraph,
  &__checkbox-wrapper {
    font-size: 11px;
  }

  &__checkbox-wrapper {
    display: flex;
    gap: 15px;
    padding-left: 45px;
    margin-bottom: 8px;
  }

  &__checkbox-input {
    width: 20px;
    height: 20px;
    margin-left: -45px !important;
    margin-top: -1px;
  }

  &__submit-btn {
    display: block;
    margin: 16px auto 10px;
    padding: 7.2px 19px 7.2px 20px;
    border-radius: 4px;

    @include screen-md-max {
      width: 100%;
      margin-bottom: 0;
    }
  }
}

.contact-info {
  margin-top: 79px;
  margin-bottom: 100px;

  @include screen-md-max {
    text-align: center;
    margin-top: 35px;
    margin-bottom: 0;
  }

  &__row {
    &:first-child {
      margin-bottom: 36px;

      @include screen-md-max {
        margin-bottom: 0;
      }
    }
  }

  &__el {
    @include screen-md-max {
      margin-bottom: 35px;
    }
  }
}

.lead-generation-form {
  &__thank-you {
    text-align: center;
    display: flex;
    flex-direction: column;

    @include screen-md-max {
      height: calc(100vh - 130px);
    }
  }
}
