.events {
  &__header {
    &__badge {
      border: 1px solid $success;
      background-color: #c6dfcb;
      color: $success;
      padding: 3px 8px;
      border-radius: 0.5rem;
      font-size: 11px;
      font-weight: bold;
    }

    &__title {
      font-size: 35px;
      color: white;
      font-weight: bold;
      letter-spacing: 1px;

      &__shadow {
        background-size: 1px 1em;
        box-shadow: inset 0 0 white, inset 0 -0.4em rgba($gold, 0.6);
        display: inline;
      }
    }

    &__content {
      position: absolute;
      top: 150px;
      left: 50%;
      transform: translateX(-50%);

      @include screen-md-max {
        background-image: url('@assets/images/events/header.webp');
        object-position: left;
        position: relative;
        padding-top: 75px;
        padding-bottom: 120px;
        top: 0;
        text-align: center;
        left: 0;
        transform: none;
      }
    }

    &__img {
      object-fit: cover;
      width: 100%;
      max-height: calc(100vh - 35px);
      height: calc(100vh - 35px);
      display: block;

      @include screen-md-max {
        display: none;
      }
    }

    &__scroll {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      width: 300px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &__text {
        z-index: 1;
        font-weight: 500;
        font-size: 11px;
        color: $primary;
        margin-bottom: 3px;
      }

      .scroll {
        border-color: $primary;
        margin-bottom: 6px;

        &:after {
          background-color: $primary;
        }
      }

      &:before {
        position: absolute;
        content: '';
        bottom: -70px;
        left: 50%;
        transform: translateX(-50%) rotate(135deg);
        height: 140px;
        width: 140px;
        background-color: white;
        clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
        border-radius: 0 0 0 10px;
      }
    }
  }

  &__benefits {
    &__point {
      color: white;
      font-size: 15px;
      position: relative;

      &:before {
        position: relative;
        z-index: 1;
        padding-top: 8px;
      }

      &:after {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        background-color: $primary;
        border-radius: 50%;
        left: -3px;
        top: -3px;
        z-index: 0;
      }
    }

    &__image-first {
      margin-left: auto;
      width: 85%;
      height: 250px;
      background: gray;
      border-radius: 0.5rem;
      object-fit: cover;
      display: block;

      @include screen-md-max {
        width: 100%;
      }
    }

    &__image-second {
      width: 50%;
      height: 250px;
      background: gray;
      border-radius: 0.5rem;
      margin-top: 15px;
      margin-right: 15px;
      margin-left: -60px;
      object-fit: cover;

      @include screen-md-max {
        margin-left: -15px;
      }
    }

    &__image-third {
      border-radius: 0.5rem;
      height: 180px;
      margin-top: 15px;
      width: 60%;
      object-fit: cover;

      @include screen-md-max {
        width: 50%;
      }
    }
  }

  &__contact {
    &__input {
      padding: 8px 15px;
      border: 1px solid #d1d1d1;
      border-radius: 4px;
      height: 41px;
      background-color: #f1f4f5;
      letter-spacing: 1px;
      width: 100%;

      &:focus {
        outline: none;
        background-color: white;
      }
    }

    &__list {
      position: relative;
      margin-top: 80px;

      @include screen-md-max {
        margin-top: 30px;
      }

      &:after {
        content: '';
        position: absolute;
        top: 10px;
        left: 0;
        height: 90%;
        width: 2px;
        background-color: $primary;
      }

      &__count {
        position: relative;
        color: white;
        font-size: 18px;
        font-weight: bold;
        display: flex;
        align-items: center;

        &__number {
          position: relative;
          margin-left: -4px;
          margin-top: 16px;
          z-index: 2;
          line-height: 1;
        }

        &:before {
          content: '';
          position: absolute;
          left: -14px;
          top: 10px;
          background-color: $primary;
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }
      }
    }
  }

  &__references {
    border-bottom-right-radius: 150px;

    .carousel-indicators {
      margin-bottom: -50px;
      display: flex;
      align-items: center;

      button {
        width: 8px;
        height: 8px;
        border-radius: 50%;

        &.active {
          width: 10px;
          height: 10px;
        }
      }
    }
  }

  &__attractions {
    border-bottom-left-radius: 150px;
    background: #f1f1f1;
  }

  &__footer {
    border-top-right-radius: 150px;
    background: #f1f1f1;
    padding-top: 100px;
    overflow: hidden;
    padding-bottom: 40px;

    @include screen-md-max {
      padding-top: 40px;
    }

    &__img {
      position: absolute;
      right: 80px;
      width: 320px;
      bottom: -20px;
    }
  }

  &__card {
    min-height: 450px;
    height: 450px;

    &__img {
      width: 100%;
      object-fit: cover;
      height: 450px;
    }
  }

  &__realizations {
    &__card {
      background-color: transparent;
      height: 450px;
      perspective: 1000px;

      @include screen-md-max {
        width: 30%;
      }

      &:hover {
        .events__realizations__card-side {
          transform: rotateY(180deg);
        }
      }
    }

    &__card-side {
      position: relative;
      width: 100%;
      height: 100%;
      text-align: center;
      transition: transform 0.6s;
      transform-style: preserve-3d;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

      &--back,
      &--front {
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
      }

      &--back {
        transform: rotateY(180deg);
      }
    }
  }
}

#events-taken-carousel,
#events-what-we-offer-carousel {
  .carousel__button {
    color: white;
    height: 50px;
  }

  .carousel__dots {
    display: none;
  }
}
