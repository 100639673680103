.big-card {
  background: #fff;
  box-shadow: 0px 3px 18px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  padding: 38px 37px;

  @include screen-md-max {
    box-shadow: none;
    padding: 0 25px;
  }
}

.small-card {
  width: 250px;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 3px 20px -7px rgba(0, 0, 0, 0.28);
  margin-top: 15px;
  margin-right: 12px;

  @include screen-sm-max {
    width: 300px;
  }

  &:not(.is-no-shrink) {
    @include screen-sm-max {
      width: 100%;
      margin-right: 0;
    }
  }

  &.is-no-shrink {
    flex-shrink: 0;
  }

  &.is-extra-padding {
    padding: 16px 16px 14px;
    margin-right: 0;
  }

  &.is-full-width {
    width: 100%;
  }

  &__card-content {
    padding: 12px 15px 50px;

    &__header {
      min-height: 59px;
    }
  }

  &__card-label {
    background-color: #fff;
    color: $darker-red;
    font-size: 10px;
    padding: 2px 9px;
    display: flex;
    align-items: center;
    position: absolute;
    margin-left: 15px;
    margin-top: -14px;
    border-radius: 5px;

    &.is-neutral {
      color: $darker-gray;
    }

    &.is-gold {
      background-color: #F5EBDB;
      color: $gold;
      border: 1px solid $gold;
    }

    &.is-red-border {
      border: 1px solid $darker-red;
    }

    &.is-relative {
      position: relative;
      margin: 0;
      width: fit-content;
      display: block;
    }

    &.no-line-height {
      line-height: 0;
    }
  }

  &__card-footer {
    font-size: 17px;
    padding: 10px 0 8px;
    margin: 0;
    position: absolute;
    bottom: 0;

    &.is-small {
      font-size: 10px;
    }
  }

  &__card-img {
    width: 100%;
    height: auto;
  }

  &__cards-overflow {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 20px;

    &.is-lg {
      @include screen-lg-max {
        flex-wrap: nowrap;
        justify-content: flex-start;
        overflow: auto;
      }
    }

    &.is-md {
      @include screen-md-max {
        flex-wrap: nowrap;
        justify-content: flex-start;
        overflow: auto;
      }
    }
  }

  &__new-badge {
    background-color: $gold;
    color: #fff;
    height: 75px;
    width: 75px;
    min-width: 75px;
    min-height: 75px;
    left: 16px;
    bottom: 7px;
    border-radius: 50%;
    text-transform: uppercase;
    position: absolute;
    font-size: 11px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;

    &.is-upper-right {
      right: 7px;
      left: initial;
      top: 7px;
      bottom: initial;
    }

    &.is-relative {
      position: relative;
    }
  }
}
