$gold: #b99153;
$gold-dark: #937648;
$dark-blue: #0a173e;
$red: #da001a;
$darker-red: #b91313;
$green-pearl: #2c8f41;
$light-azure: #ecf5fc;
$darker-gray: #576067;
$light-gray: #fcfcfc;
$light-black: #0c0c0c;
$anthracite: #656565;
$alice-blue: #f8f9fa;
$silver: #a3a3a3;
$brown: #562c0c;

// Breakpoints
$screen-xxl-min: 1770px;
$screen-xl-min: 1200px;
$screen-xl-max: ($screen-xxl-min - 1);
$screen-lg-min: 992px;
$screen-lg-max: ($screen-xl-min - 1);
$screen-md-min: 768px;
$screen-md-max: ($screen-lg-min - 1);
$screen-sm-min: 576px;
$screen-sm-max: ($screen-md-min - 1);
$screen-xs-min: 480px;
$screen-xs-max: ($screen-sm-min - 1);
$screen-xxs-max: 375px;

// Fonts
$font-xxs: 0.8rem;
$font-xs: 0.85rem;
$font-sm: 0.9rem;
$font-md: 0.95rem;
$font-lg: 1.1rem;
$font-xl: 1.2rem;
$font-xxl: 1.45rem;

$primary: $gold;
$secondary: $dark-blue;
$success: $green-pearl;
$attention-red: $darker-red;
$danger: $red;

$dark-gray-gradient: linear-gradient(#606a72, #444c52);

$enable-negative-margins: true;
