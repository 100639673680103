.gallery {
  &__indicator {
    width: 75px !important;
    height: 48px !important;
    opacity: 1 !important;

    &__wrapper {
      position: relative;

      &:after {
        z-index: -1;
        content: '';
        position: absolute;
        width: 79px;
        height: 52px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
      }
    }
  }

  &__counter {
    position: absolute;
    left: 30px;
    bottom: -10px;
    background-color: #00000060;
    padding: 5px 11px;
    color: white;
    font-size: 11px;
    font-weight: 500;
    border-radius: 2px;

    //@include screen-md-max {
    //  bottom: 50px;
    //}
  }

  .carousel-indicators {
    bottom: -35px;
    max-width: 100%;
    overflow: auto;
    justify-content: left;
    width: fit-content;
    margin: 0 auto;
    padding: 0 4px;
  }

  .carousel-control-prev, .carousel-control-next {
    width: 10%;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }


  @include screen-sm-max {
    padding: 4px;
    margin-bottom: 50px;
  }

  ::-webkit-scrollbar {
    height: 7px;
  }

  ::-webkit-scrollbar-track {
    background-color: #e0e0e0;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #a5a5a5;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgba(#a5a5a5, 0.7);
  }
}

.carousel-indicators {
  @include screen-sm-max {
    display: none;
  }
}

.carousel-item {
  clear: both;
}

.carousel-item.carousel-item-next.carousel-item-start, .carousel-item.carousel-item-prev.carousel-item-end {
  position: absolute;
  top: 0;
}