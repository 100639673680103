.picture-bar {
  height: 200px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);

  @include screen-md-max {
    grid-template-columns: repeat(3, 1fr);
  }

  &__image {
    object-fit: cover;
    width: 100%;
    height: 200px;
  }
}
