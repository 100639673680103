.blog {
  &__section {
    margin-top: 51px;
    margin-bottom: 55px;
    padding: 0 0 43px;

    &__image {
      text-align: center;
      color: #fff;
      background-repeat: no-repeat;
      background-size: cover;
      height: 300px;
      width: 100%;
    }
    &__title {
      padding-top: 220px;
    }

    @include screen-md-max {
      margin-top: 0;
      margin-bottom: 25px;
    }
  }

  &__section-content {
    padding: 40px 100px 0;

    @include screen-md-max {
      padding: 30px 15px 0;
    }
  }

  &__section-header {
    margin: 50px auto;
  }

  &__pagination {
    &__input {
      border-radius: 4px;
      border: 1px solid #a2abb3ff;
      height: 40px;
      padding: 10px;
      text-align: center;
      font-size: 14px;

      &:focus-visible {
        outline: none;
      }
    }

    &__all-pages {
      font-weight: 500;
      font-size: 15px;
      display: flex;
      align-self: center;
      margin-left: 5px;
    }
  }
}
