.popup {
  &__close-icon {
    z-index: 1;
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 23px;
    opacity: 0.8;
    color: #000000;
    cursor: pointer;
    transition: opacity 200ms linear;

    &:hover {
      opacity: 1;

      &:after {
        background-color: #d6d6d661;
      }
    }

    &:before {
      position: relative;
      z-index: 1;
    }

    &:after {
      box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
      transition: background-color 200ms linear;
      content: '';
      width: 35px;
      height: 35px;
      background-color: rgba(214, 214, 214, 0.21);
      border-radius: 50%;
      left: -6px;
      border: 1px solid #dfdfdf29;
      position: absolute;
      top: -1px;
    }
  }

  &__mobile-image {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @include screen-sm-min {
      display: none;
    }
  }

  &__desktop-image {
    display: none;
    width: 100%;
    height: 100%;
    object-fit: cover;

    @include screen-sm-min {
      display: block;
    }
  }

  &__single-row-three-column {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: 1fr;

    @include screen-sm-min {
      grid-template-rows: 1fr;
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
