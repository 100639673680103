.single-resort {
  &__section {
    margin-top: 51px;
    margin-bottom: 70px;
    padding: 0;

    @include screen-md-max {
      margin-top: 0;
      margin-bottom: 0;
      padding: 0 0.75rem;
    }
  }

  &__section-header {
    padding: 23px 32px;

    @include screen-md-max {
      padding: 23px 0 0;
      display: flex;
      flex-direction: column-reverse;
    }
  }

  &__locale-header {
    font-size: 36px;
    margin-bottom: 0;

    @include screen-md-max {
      margin: 10px 0;
    }

    &__map-button {
      height: 30px;
      padding: 1px;

      &__icon {
        width: 78px;
        height: 100%;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
      }
    }
  }

  &__distance-box {
    padding: 4px 17px;
    background-color: rgba(0, 0, 0, 0.1);

    @include screen-md-max {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px 10px;
    }
  }

  &__reservation-box {
    border-top: 1px solid #dadada;
  }

  &__reservation-box-el {
    border-right: 1px solid #dadada;
    padding: 15px 29px;

    &:last-child {
      border-right: none;
    }
  }

  &__locale-info {
    font-size: 15px;

    @include screen-md-max {
      font-size: 12px;
    }
  }

  &__main-img {
    height: auto;
    width: 100%;

    @include screen-md-max {
      max-height: 300px;
    }
  }

  &__content {
    padding: 0 100px;

    @include screen-md-max {
      padding-inline: 0;
    }
  }

  &__attractions {
    padding: 16px 27px;
    background-color: #eff3f7;
    display: flex;
    flex-wrap: wrap;

    @include screen-md-max {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }

    @include screen-sm-max {
      grid-template-columns: repeat(2, 1fr);
    }

    &.no-bg {
      background: none;
    }
  }

  &__interactive-box-text {
    font-weight: 300;
    font-size: 15px;
    min-height: 50px;
  }

  &__social-icon {
    height: 18px;
    width: auto;
    margin-right: 12px;

    @include screen-md-max {
      margin-right: 7px;
    }
  }

  &__social-icon-wrapper {
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-right: 35px;

    &:last-child {
      margin-right: 0;
    }

    @include screen-md-max {
      margin-right: 15px;
    }

    @include screen-xs-max {
      margin-right: 0;
    }
  }

  &__localization {
    background-color: #eff3f7;
    height: 208px;
    width: 100%;
  }

  &__map-modal {
    &__body {
      overflow-y: auto;
      max-height: 85vh;
      position: initial;
    }

    &__zoom-buttons {
      &__wrapper {
        position: absolute;
        bottom: 20px;
        right: 20px;
        display: flex;
        flex-direction: column;
        border-radius: 4px;
        margin-left: auto;
        width: 35px;

        button:active {
          background-color: #ebebeb;
        }
      }

      &__spacer {
        width: 70%;
        margin: 0 auto;
      }
    }
  }
}
