.accommodation {
  &__small-cards-wrapper {
    margin-bottom: 52px;

    @include screen-md-max {
      padding: 0 15px;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__card {
    padding: 0;
    margin-bottom: 50px;

    @include screen-md-max {
      padding: 0 15px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;

    @include screen-md-max {
      padding: 20px 0;
    }

    @include screen-xs-max {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__card-header-text {
    color: $dark-blue;

    @include screen-md-max {
      color: $gold;
      background-size: 1px 1em;
      box-shadow: inset 0 0 white, inset 0 -0.4em rgba($gold, 0.3);
      display: inline;
    }
  }

  &__card-label {
    background-color: #E6E6E6;
    padding: 6px 9px;

    @include screen-xs-max {
      margin-top: 8px;
    }
  }

  &__card-content {
    padding: 45px 100px;
    position: relative;
    overflow: hidden;

    @include screen-md-max {
      padding: 45px 0;
    }
  }

  &__rooms {
    padding: 16px 27px;
    background-color: #eff3f7;
    display: flex;
    flex-wrap: wrap;

    @include screen-md-max {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }

    @include screen-sm-max {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__accordion {
    border: none;
    border-radius: 4px;
  }

  &__accordion-button {
    background-color: transparent !important;
    font-size: 14px;
    border: 1px solid $darker-gray;
    color: $darker-gray !important;

    &:hover {
      border-color: $gold !important;
      color: $gold !important;
    }

    &:focus {
      border-color: $darker-gray;
      color: $darker-gray;
      box-shadow: none;
    }

    &:after {
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e") !important;
    }

    &:hover:after {
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23a7834b%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e") !important;
    }
  }
}
